
* {
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.pac-container {
  z-index: 99999999;  /* or any large number you prefer */
}

.related-card-comment p {
  margin: 0px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.blueRow {
  background-color: #C7D9F0 !important;
}

.redRow {
  background-color: rgba(255, 118, 118, .5) !important;
}

.greenRow {
  background-color: rgba(121, 205, 121, .5) !important;
}

.orangeRow {
  background-color: rgba(255, 171, 71, .5) !important;
}

.yellowRow {
  background-color: rgba(255, 229, 100, .5) !important;
}

.purpleRow {
  background-color: rgba(185, 119, 255, .5) !important;
}

.pinkRow {
  background-color: rgba(255, 149, 230, .5) !important;
}

.tealRow {
  background-color: rgba(138, 255, 236, .5) !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.collapsedNoteContent p {
  margin: 0px;
  padding: 0px;
  font-size: 13px;
}

.collapsedNoteContent a {
  color: #2e59a8;
  font-weight: 500;
}

.App-link {
  color: #61dafb;
}

.property-search-box {
  box-shadow: 0 1px 2px rgba(0,0,0,.05);
  border: 1px solid rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 30px;
  text-align: left;
  font-size: 14px;
}

.loading-bar-container {
  width: 100%;
  background-color: #eee;
  border: 1px solid #ddd;
  position: relative;
  padding: 5px;
}

.loading-bar {
  height: 20px;
  background-color: #4CAF50; /* Green background for the loading bar */
  width: 0%; /* Initial width */
  transition: width 0.5s ease-in-out;
}

.loading-label {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 30px; /* Adjust based on your design */
}


.property-search-header {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 24px 24px 24px 24px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above everything */
}

.loading-content {
  text-align: center;
  color: white;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top: 4px solid #fff;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.tox-tinymce { 
  border: none !important; 
}

.subjectEditorContainer .tox-tinymce {
  height: 50px !important;
  margin-bottom: 10px;
}

.pageHeader {
  background-color: white;
  padding-left: 20px;
  border-bottom: 1px solid rgb(236, 239, 242)
}

.completed-todo {
  opacity: 0.5;
}

@media screen and (max-width: 600px) {
  .pageHeader {
    margin-left: 25px;
  }
  .atlasAnnouncement {
    margin-left: 0px !important;
  }
}

.pageHeaderText {
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  margin-left: 15px;
  color: rgba(0, 0, 0, 0.8);
}

.navBar a {
  width: 100%;
}

.navBar .active {
  background-color: rgba(25, 118, 210, 0.08);
  border-radius: 3px;
}

.tox-statusbar {
  display: none !important;
}

.mce-edit-focus {
  outline: none;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox {
  padding: 0px;
}

.MuiAutocomplete-popper .MuiAutocomplete-listbox .MuiAutocomplete-option {
  padding: 0px;
}

.MuiDataGrid-rowReorderCell .MuiSvgIcon-root{
  opacity: .5;
}

.MuiDataGrid-rowReorderCell .MuiSvgIcon-root:hover{
  opacity: 1;
}

/* .MuiDataGrid-row:last-child .MuiDataGrid-cellCheckbox {
  background-image: url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox=%270 -10 60 60'%3E%3Cpath d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z'/%3E%3C/svg%3E" );
  background-size: 60px;
  opacity: .5;
}



.MuiDataGrid-row:last-child .MuiDataGrid-cellCheckbox .MuiCheckbox-root {
  display: none;
} */

.MuiDataGrid-pinnedColumns .MuiDataGrid-row .MuiCheckbox-root {
  visibility: hidden;
}

.MuiDataGrid-row .MuiDataGrid-cellCheckbox:hover .MuiCheckbox-root {
  visibility: visible;  
}

.MuiDataGrid-row .Mui-checked {
  visibility: visible;  
}

.MuiDataGrid-columnHeaders{
  border-radius: 0px;
  background-color: rgb(245, 246, 248);
}

#commentIcon {
 color: rgb(40, 42, 48, 0.5);
}

.fileViewsBox {
  padding: 10px !important;
}

#commentIcon:hover {
  color: rgb(40, 42, 48);
 }

.MuiDataGrid-columnHeader:hover {
  background-color: #ececec !important;
  border-radius: 3px;
 }

 .mention {
  color: #046aad;
  font-weight: bold;
 }

 .MuiDataGrid-row .Mui-selected {
  border-radius: 3px;
 }

 .MuiDataGrid-columnHeader:hover .MuiDataGrid-sortIcon {
  display: none !important;
  opacity: 0 !important;
 }

 .searchInvHeader {
  padding-top: 5px !important;
 }

 .noteMenuOpen {
  opacity: 1 !important;
  background-color: #f6f6f6 !important;
  border-radius: 5px !important;
 }
 
 /* If it's sorted display on hover */
 .MuiDataGrid-columnHeader--sorted:hover .MuiDataGrid-sortIcon {
  display: block !important;
  opacity: 1 !important;
 }

 .MuiDataGrid-menuIconButton {
  opacity: 0 !important;
 }

.flexContainer {
  display: flex !important;
  width: 100%;
}

 .timelineHeader {
  color: var(--colors-text-text-primary-on-brand, #FFF);
  /* Text md/Semibold */
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */
 }

 .onboardingHeader {
  color: var(--colors-text-text-primary-900, #101828);
  text-align: center;

  /* Display sm/Semibold */
  font-family: Inter;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 38px; /* 126.667% */
 }
 
 .timelineSubheader {
  color: var(--blue-200, #B2DDFF);

  /* Text md/Regular */
  font-family: Inter;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
 }

 .tox-tinymce-inline {
  z-index: 9999999;
 }

 .fr-second-toolbar {
  display: none;
 }

 .fr-wrapper {
  border-radius: 0px 0px 8px 8px;
  /* border-bottom: 1px solid #CCCCCC !important; */
 }
 
.mercero-cell {
  background-color: rgba(61,106,239,.1);
  font-weight: 500;
  color: #101828;
  /* opacity: .4; */
  /* border: 1px solid #3d6aef; */
}

.MuiDataGrid-cell {
  font-weight: 500;
}

.mapInfoBubble p {
  margin: 0px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  src: local('Inter Regular'), local('Inter-Regular'),
       url('./fonts/Inter-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'Jeko';
  font-style: bold;
  font-weight: 600;
  src: url('./fonts/Jeko Bold.otf') format('otf');
}
